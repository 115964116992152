<template>
  <div class="the-navbar__user-meta flex items-center cursor-pointer" v-if="activeCourseInfo" @click="$router.push('/courses')">
    <div class="con-img mr-4 ml-1 md:ml-0">
      <img
        :src="activeCourseInfo.thumbnail"
        alt="user-img"
        width="40"
        height="40"
        draggable="false"
        class="rounded shadow-md cursor-pointer block"
        v-if="activeCourseInfo.active && activeCourseInfo.thumbnail"
      />
    </div>

    <div class="text-left leading-tight hidden sm:flex gap-x-6" style="min-width: 145px">
      <span class="">
        <p class="font-semibold">{{ activeCourseInfo.name }}</p>
        <div style="min-width: 141px">
          <small>Click Here To Change Course</small>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
  },
};
</script>
<style lang="scss">
.vue-typer {
  .caret {
    background-color: transparent !important;
  }
}
</style>
