/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items course-classroom-list. Add / Remove menu items from here.

==========================================================================================*/

export default [
  {
    url: '/dashboard',
    name: 'Dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
  },
  /*{
    header: 'Homework',
    items: [
      {
        url: '/classes',
        name: 'Classes',
        slug: 'classes',
        icon: 'ClipboardIcon'
      },
    ]
  }, */
  {
    header: 'Learn',
    items: [
      {
        url: '/apps',
        name: 'Apps',
        slug: 'apps',
        icon: 'GridIcon',
      },
      {
        url: '/courses',
        name: 'Courses',
        slug: 'courses',
        rule: 'user',
        icon: 'BookIcon',
      },
    ],
  },
  {
    header: 'Admin - Students',
    rule: 'proctor',
    items: [
      {
        url: '/a/users',
        name: 'Users',
        slug: 'admin-users',
        icon: 'UsersIcon',
        rule: 'proctor',
      },
      /*{
        url: '/a/classrooms',
        name: 'Classes',
        slug: 'classrooms-users',
        icon: 'ClipboardIcon',
        rule: 'proctor'
      },*/
      {
        url: '/a/subscriptions',
        name: 'Subscriptions',
        slug: 'admin-subscriptions',
        icon: 'CreditCardIcon',
        rule: 'admin',
      },
    ],
  },
  {
    header: 'Admin - Platform',
    rule: 'proctor',
    items: [
      {
        url: '/a/courses',
        name: 'Courses',
        slug: 'admin-courses',
        icon: 'BookIcon',
        rule: 'proctor',
      },
      {
        url: '/a/simulations',
        name: 'Simulations',
        slug: 'admin-simulations',
        icon: 'CodesandboxIcon',
        rule: 'proctor',
      },
      {
        url: '/a/study',
        name: 'Study',
        slug: 'admin-study',
        icon: 'TargetIcon',
        rule: 'proctor',
      },
      {
        url: '/a/examedge/banks',
        name: 'ExamEdge',
        slug: 'admin-examedge',
        icon: 'FileTextIcon',
        rule: 'proctor',
      },
      {
        url: '/a/labs',
        name: 'Labs',
        slug: 'admin-labs',
        icon: 'ServerIcon',
        rule: 'proctor',
      },
    ],
  },
];
