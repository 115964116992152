<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar

========================================================================================== -->

<template>
  <div class="relative">
    <div :class="classObj" class="vx-navbar-wrapper">
      <vs-navbar :class="textColor" class="vx-navbar navbar-custom navbar-skelton">
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" />

        <active-course />

        <!--<logo class="hidden xl:block"/>-->

        <vs-spacer />

        <!--<active-course/>
        <div class="ml-6 mr-6 user-course-divider"></div>-->

        <profile-drop-down />
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import ProfileDropDown from './components/ProfileDropDown.vue';
import ActiveCourse from './components/ActiveCourse';
import Logo from './components/Logo.vue';

export default {
  name: 'the-navbar-vertical',
  props: {
    navbarColor: {
      type: String,
      default: '#fff',
    },
  },
  components: {
    ActiveCourse,
    //Bookmarks,
    //SearchBar,
    //NotificationDropDown,
    ProfileDropDown,
    Logo,
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        'text-white':
          (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') ||
          (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark'),
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth === 'default') return 'navbar-default';
      else if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced';
      else if (this.verticalNavMenuWidth) return 'navbar-full';
    },
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    },
  },
};
</script>
<style lang="scss">
.user-course-divider {
  width: 2px;
  height: 35px;
  border-radius: 0.5rem;
  background-color: rgb(15 20 25);
}

.theme-dark {
  .user-course-divider {
    background-color: rgb(37 40 44);
  }
}
</style>
