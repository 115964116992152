<!-- =========================================================================================
  File Name: VerticalNavMenu.vue
  Description: Vertical NavMenu Component
  Component Name: VerticalNavMenu
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
================================================================================.vs-sidebar========== -->

<template>
  <div class="ps-sidebar-wrapper">
    <div class="ps-sidebar-holder">
      <div>
        <switcher-app :launch-path="dash.launchPath" :icon="dash.icon" :page-names="dash.pageNames" :name="dash.name" />
        <slide-y-up-transition group tag="div">
          <!--- GET APPS FROM API --->
          <div v-for="(app, index) in apps" :key="index" v-if="apps">
            <switcher-app :launch-path="app.launchPath" :icon="app.icon" :page-names="app.pageNames" :name="app.name" />
          </div>
        </slide-y-up-transition>
      </div>

      <div v-if="userRoleCheck()">
        <switcher-app
          :launch-path="instructorTools.launchPath"
          :icon="instructorTools.icon"
          :page-names="instructorTools.pageNames"
          :name="instructorTools.name"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SwitcherApp from './components/SwitcherApp';

export default {
  data() {
    return {
      apps: [],
      dash: {
        icon: 'https://connect-cdn.intellectualpoint.com/assets/images/apps/HomeIcon.png',
        name: 'Dashboard',
        pageNames: ['dashboard'],
        launchPath: '/dashboard',
      },
      instructorTools: {
        icon: 'https://connect-cdn.intellectualpoint.com/assets/images/icons/instructor-tools/InstructorToolsIcon.png',
        name: 'Instructor Tools',
        pageNames: ['instructor-tools'],
        launchPath: '/a/instructor-tools',
      },
    };
  },
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    reduceButton: {
      get() {
        return this.$store.state.reduceButton;
      },
      set(val) {
        this.$store.commit('TOGGLE_REDUCE_BUTTON', val);
      },
    },
  },
  watch: {
    'activeCourseInfo.id'() {
      this.initSwitcher();
    },
  },
  components: {
    SwitcherApp,
  },
  methods: {
    initSwitcher() {
      this.loadApps();
    },
    loadApps() {
      this.apps = [];

      if (this.activeCourseInfo.appsEnabled && !Array.isArray(this.activeCourseInfo.appsEnabled)) {
        return;
      }

      this.activeCourseInfo.appsEnabled.forEach((app, index) => {
        setTimeout(() => {
          this.apps.push(app);
        }, index * 60);
      });
    },

    userRoleCheck() {
      if (this.activeUserInfo.role === 'console' || this.activeUserInfo.role === 'proctor' || this.activeUserInfo.role === 'admin') {
        return true;
      }
    },
  },
  created() {
    this.initSwitcher();
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/vuexy/components/platformSwitcher.scss';
</style>
