<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.fullName" id="profileDropdown">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.fullName }}</p>
      <small>Student</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="ml-4">
        <!-- <div class="con-img gradient-border-pfp"> -->
        <!--- <img v-if="activeUserInfo.avatar" key="onlineImg" :src="activeUserInfo.avatar" alt="user-img" width="40" height="40" class="rounded shadow-md cursor-pointer block" /> --->
        <img
          key="onlineImg"
          :src="'https://portal-cdn.rapid.education/profile-pictures/default.jpeg'"
          alt="user-img"
          width="40"
          height="40"
          class="rounded-full main-border shadow-md cursor-pointer block"
        />
        <!-- </div> -->
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            style="border-radius: 0.4rem"
            @click="profileSettings = !profileSettings"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            style="border-radius: 0.4rem"
            @click="$fullscreen.toggle()"
          >
            <feather-icon icon="Maximize2Icon" svgClasses="w-4 h-4" />
            <span class="ml-2">Full Screen</span>
          </li>

          <li
            ref="logoutButton"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            style="border-radius: 0.4rem"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>

    <vs-popup :active.sync="profileSettings" v-on:close="profileSettings = false" title="User Profile">
      <div class="p-2">
        <div class="flex items-center">
          <img
            v-if="activeUserInfo.avatar === 'default'"
            :src="defaultProfilePicture"
            alt=""
            width="75"
            height="75"
            class="rounded shadow-md mr-4"
            draggable="false"
          />
          <img v-else :src="activeUserInfo.avatar" alt="" draggable="false" width="75" height="75" class="rounded shadow-md mr-4" />
          <div>
            <h1 class="font-bold">{{ activeUserInfo.fullName }}</h1>
            <h5 class="font-light">{{ activeUserInfo.email }}</h5>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import LottieAnimation from '../../../../components/lottie/LottieAnimation';
import jwt from '../../../../services/jwt';

export default {
  data() {
    return {
      profileSettings: false,

      defaultProfilePicture: 'https://portal-cdn.rapid.education/profile-pictures/default.jpeg',
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    currentTheme() {
      return this.$store.state.theme;
    },
  },
  methods: {
    logout() {
      jwt.logout();
    },
  },
  components: {
    LottieAnimation,
  },
};
</script>
<style lang="scss">
.gradient-border-pfp {
  --borderWidth: 2px;
  background: #1d1f20;
  position: relative;
  border-radius: var(--borderWidth);
}
.gradient-border-pfp:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #0053ff, #0017b7, #0053ff, #009eff, #0017b7, #00b0ff);
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

button.vs-con-dropdown.parent-dropdown.cursor-pointer {
}
</style>
