<template>
  <vx-tooltip :text="name" position="right" class="switcher-app" color="#0053ff">
    <div class="ps-sidebar-item-block" v-if="pageNames" :class="pageNames.includes($route.name) ? 'active-platform' : ''">
      <div class="ps-line-active"></div>
      <vs-avatar size="large" color="transparent" :src="icon" @click="$router.push(launchPath)" />
    </div>
  </vx-tooltip>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: false,
    },
    pageNames: {
      type: Array,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    launchPath: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.switcher-app {
  user-select: none;
}
</style>
