<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div :class="[layoutTypeClass, navbarClasses, footerClasses, { 'no-scroll': isAppPage }]" class="layout--main">
    <switcher class="ps-switcher-root" />
    <v-nav-menu :navMenuItems="navMenuItems" ps-sidebar-wrapper parent=".layout--main" title="Learn" />

    <div id="content-area-wrapper">
      <div id="content-area" :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]">
        <div id="content-overlay" />

        <template>
          <the-navbar-vertical
            :class="[{ 'text-white': isNavbarDark && !isThemeDark }, { 'text-base': !isNavbarDark && isThemeDark }]"
            :navbarColor="navbarColor"
          />
        </template>

        <div class="content-root">
          <div class="content-wrapper">
            <div class="router-view">
              <div class="router-content">
                <transition :name="routerTransition">
                  <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                    <div
                      :class="{ 'pr-4 border-0 md:border-r border-solid border-grey-light': $route.meta.breadcrumb }"
                      class="content-area__heading"
                    >
                      <h2 class="mb-1">{{ this.$route.meta.pageTitle }}</h2>
                    </div>

                    <!-- BREADCRUMB -->
                    <vx-breadcrumb v-if="$route.meta.breadcrumb" :isRTL="$vs.rtl" :route="$route" class="ml-4 md:block hidden" />

                    <!-- DROPDOWN
                      <vs-dropdown vs-trigger-click class="ml-auto md:block hidden cursor-pointer">
                        <vs-button radius icon="icon-settings" icon-pack="feather" />
      
                        <vs-dropdown-menu class="w-32">
                          <vs-dropdown-item>
                            <div @click="$router.push('/pages/profile').catch(() => {})" class="flex items-center">
                              <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                              <span>Profile</span>
                            </div>
                          </vs-dropdown-item>
                          <vs-dropdown-item>
                            <div @click="$router.push('/apps/todo').catch(() => {})" class="flex items-center">
                              <feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                              <span>Tasks</span>
                            </div>
                          </vs-dropdown-item>
                          <vs-dropdown-item>
                            <div @click="$router.push('/apps/email').catch(() => {})" class="flex items-center">
                              <feather-icon icon="MailIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                              <span>Inbox</span>
                            </div>
                          </vs-dropdown-item>
                        </vs-dropdown-menu>
      
                      </vs-dropdown> -->
                  </div>
                </transition>

                <div class="content-area__content">
                  <back-to-top
                    v-if="!hideScrollToTop"
                    :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'"
                    bottom="5%"
                    visibleoffset="500"
                  >
                    <vs-button class="shadow-lg btn-back-to-top" icon="icon-arrow-up" icon-pack="feather" />
                  </back-to-top>

                  <transition :name="routerTransition" mode="out-in">
                    <router-view @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
                  </transition>
                </div>
              </div>
            </div>
          </div>
          <the-footer />
        </div>
      </div>
    </div>

    <vs-popup :active.sync="developerTools" title="Developer Tools">
      <h1 class="mb-3 font-bold">Developer Tools</h1>
      <div class="flex justify-between items-center mb-2">
        <h3>Full name :</h3>
        <h3>{{ activeUserInfo.firstName }} {{ activeUserInfo.lastName }}</h3>
      </div>

      <div class="flex justify-between items-center mb-2">
        <h3>Full name</h3>
        <h3>{{ activeUserInfo.firstName }} {{ activeUserInfo.lastName }}</h3>
      </div>
      <div class="flex justify-between items-center mb-2">
        <h3>User ID</h3>
        <h3 @click="copyText(activeUserInfo.id)">{{ activeUserInfo.id }}</h3>
      </div>
      <div class="flex justify-between items-center mb-2">
        <h3>Email</h3>
        <h3 @click="copyText(activeUserInfo.email)">{{ activeUserInfo.email }}</h3>
      </div>
      <div class="flex justify-between items-center mb-4">
        <h3>Active Course</h3>
        <h3 @click="copyText(activeUserInfo.active_course)">{{ activeUserInfo.active_course }}</h3>
      </div>
      <div class="flex justify-between pt-2">
        <div class="flex items-center">
          <vs-button class="mr-4" type="gradient" @click="copyText('sessionToken')">Copy Session Token</vs-button>
          <vs-button type="gradient" @click="copyText('protectionKey')">Copy Protection Key</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import BackToTop from 'vue-backtotop';
import navMenuItems from '@/layouts/components/vertical-nav-menu/navMenuItems.js';
import TheNavbarVertical from '@/layouts/components/navbar/TheNavbarVertical.vue';
import TheFooter from '@/layouts/components/TheFooter.vue';
import themeConfig from '@/../themeConfig.js';
import VNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue';
import Switcher from './Switcher';

export default {
  components: {
    BackToTop,
    TheFooter,
    TheNavbarVertical,
    VNavMenu,
    Switcher,
  },
  data() {
    return {
      backgroundMusic: null,
      developerTools: false,
      footerType: themeConfig.footerType || 'static',
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || '#fff',
      navbarType: themeConfig.navbarType || 'floating',
      navMenuItems,
      routerTransition: themeConfig.routerTransition || 'none',
      routeTitle: this.$route.meta.pageTitle,
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
      if (window.VGReady === true) {
        this.$VoriantGuard.sendPresenceUpdate();
      }
    },
    isThemeDark(val) {
      // const color = this.navbarColor === '#fff' && val ? '#1B1E22' : '#fff'
      const color = this.navbarColor === '#fff' && val ? '#050056' : '#fff';
      this.updateNavbarColor(color);
    },
    '$store.state.mainLayoutType'(val) {
      this.setNavMenuVisibility(val);
    },
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },
    contentAreaClass() {
      if (this.mainLayoutType === 'vertical') {
        if (this.verticalNavMenuWidth === 'default') return 'content-area-reduced';
        else if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg';
        else return 'content-area-full';
      } else return 'content-area-full';
    },
    footerClasses() {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static',
      };
    },
    isAppPage() {
      return this.$route.meta.no_scroll;
    },
    isThemeDark() {
      return this.$store.state.theme === 'dark';
    },

    layoutTypeClass() {
      return `main-${this.mainLayoutType}`;
    },
    mainLayoutType() {
      return this.$store.state.mainLayoutType;
    },
    navbarClasses() {
      return {
        'navbar-hidden': this.navbarType === 'hidden',
        'navbar-sticky': this.navbarType === 'sticky',
        'navbar-static': this.navbarType === 'static',
        'navbar-floating': this.navbarType === 'floating',
      };
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    appReadyCheck() {
      this.$http
        .get('health')
        .then((response) => {
          if (response.status !== 200) {
            this.$router.push('/500');
          }
        })
        .catch(() => {
          this.$router.push('/500');
        });
    },
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbarColor(val) {
      this.navbarColor = val;
      this.isNavbarDark = val !== '#fff';
    },
    setNavMenuVisibility(layoutType) {
      if ((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === 'vertical' && this.windowWidth < 1200)) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false);
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu');
      } else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
      }
    },
    copyText(x) {
      let text = x;
      if (x === 'sessionToken' || x === 'protectionKey') {
        const token = localStorage.getItem(x);
        text = token ? token : '';
      }
      const thisIns = this;
      this.$copyText(text).then(function () {
        thisIns.$vs.notify({
          title: 'Success',
          text: 'Text Copied to Clipboard',
          color: 'success',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-clipboard',
        });
      });
    },
    developerMode(e) {
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'D'.charCodeAt(0)) {
        e.preventDefault();
        this.developerTools = !this.developerTools;
      }
    },
  },
  beforeMount() {
    this.appReadyCheck();
  },
  created() {
    const color = this.navbarColor === '#fff' && this.isThemeDark ? '#1B1E22' : this.navbarColor;
    this.updateNavbarColor(color);
    this.setNavMenuVisibility(this.$store.state.mainLayoutType);

    // Setup Developer Mode
    if (this.$acl.check('admin')) {
      window.addEventListener('keydown', this.developerMode);
    }

    // Setup EasterEgg

    // Setup Userback
    //Userback.setEmail(this.activeUserInfo.email)
    //Userback.setName(this.activeUserInfo.fullName)
  },
};
</script>
