<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter

========================================================================================== -->


<template functional>
  <footer class="the-footer flex-wrap justify-between items-center select-none" :class="classes">
    <p class="flex items-center">
      <span>Copyright &copy; {{ new Date().getFullYear() }} Intellectual Point. All rights reserved.</span>
    </p>
    <span class="md:flex hidden items-center">
       <span class="hidden sm:inline-block"> Designed with <font-awesome-icon class="text-danger mr-1 ml-1" :icon="['fas', 'heart']" />
         by <a href="https://intellectualpoint.com">Intellectual Point ™</a>
       </span>
    </span>
  </footer>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core'
import {faHeart} from '@fortawesome/free-solid-svg-icons'

library.add(faHeart)

export default {
  name: 'the-footer',
  props: {
    classes: {
      type: String
    }
  }
}
</script>
